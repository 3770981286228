/* eslint-disable import/max-dependencies */
// @flow
import React, { useEffect, type Node } from "react";
import { useSelector, useDispatch, useStore } from "react-redux";
import { IconButton } from "@mui/material";
import {
  useTable,
  useHandleRequest,
  type UseTableHook,
  type UseHandleRequestHook,
} from "@fas/cpa-state-manager/redux/hooks";
import type { Column } from "@fas/cpa-cabinet-ui/lib/Table/Table.types";
import {
  type Filters,
  type Sorting,
} from "@fas/cpa-state-manager/redux/actions/table/actions";
import {
  getTableFilters,
  getTableSorting,
  getTablePage,
  getTablePageSize,
  getTableFields,
  getTableData,
} from "@fas/cpa-state-manager/services/selectors/table";
import {
  changeTableItemsTotalAmount,
  changeTableFilters,
  changeTablePage,
  setTableData,
} from "@fas/cpa-state-manager/redux/actions/table";
import { type Response } from "@fas/ui-framework/lib/services/request";
import type { StoreWithLoading } from "@fas/cpa-state-manager/services/selectors/loading";
import { getLoadingState } from "@fas/cpa-state-manager/services/selectors/loading";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications/actions";
import {
  PAYMENT_HISTORY_TABLE,
  PERIOD_STATE_TO_PAID,
  PERIOD_STATE_TO_STATUS_MAPPING,
  PERIOD_STATE_REFUND,
} from "../../constants";
import {
  fetchPaymentHistoryList,
  exportToPdf,
  exportToZip,
  type RequestBody,
} from "../../services/paymentHistoryApi";
import {
  downloadPdf,
  downloadZip,
  formatCurrency,
  getFileNameFromHeaders,
  isEqualPreviousBuilder,
  readObjFromFile,
} from "../../utils";
import type { PaymentHistoryData } from "../../components/PaymentHistory";
import Download from "../../icons/download";

type PaymentHistoryColumnData = PaymentHistoryData & { invoice: * };
export type UsePaymentHistoryTable = {
  columns: Column<PaymentHistoryColumnData>[],
};
export type UsePaymentHistoryActions = {
  classes?: *,
  filters: Filters,
  isLoading: boolean,
  isExportLoading: boolean,
  isNoData: boolean,
  onApply: () => mixed,
  onExport: () => mixed,
  onChangeDate: ({ startDate: string, endDate: string }) => mixed,
};

const isEqualPrevious: (*) => boolean = isEqualPreviousBuilder();

// eslint-disable-next-line max-len
export const getColumns: ((id: number) => mixed, isLoadingExport: boolean) => Column<PaymentHistoryColumnData>[] = (onExport, isLoadingExport) => [
  {
    field: "created",
    label: "Created",
    sortable: true,
  },
  {
    field: "dateFrom",
    label: "Period from",
    sortable: true,
  },
  {
    field: "dateTo",
    label: "Period to",
    sortable: true,
  },
  {
    field: "paidTotal",
    label: "Paid total",
    sortable: true,
    render: (({ currency, paidTotal }: PaymentHistoryData): Node => (
      <span key={`${currency}-${paidTotal}`}>
        {formatCurrency(currency, paidTotal)}
      </span>
    )),
  },
  {
    field: "paidAt",
    label: "Paid on",
    sortable: true,
  },
  {
    field: "stateId",
    label: "Status",
    sortable: true,
    render: (({ stateId }: PaymentHistoryData): Node => (
      <span>
        {PERIOD_STATE_TO_STATUS_MAPPING[stateId]}
      </span>
    )),
  },
  {
    field: "invoice",
    label: "Invoice",
    render: (({ id, stateId, isDownloadable }: PaymentHistoryData): Node => (
      (isDownloadable === true
        || (isDownloadable === undefined && (stateId === PERIOD_STATE_TO_PAID || stateId === PERIOD_STATE_REFUND))) && (
        <IconButton
          disabled={isLoadingExport}
          sx={(theme: *): * => ({
            margin: -1,
            [theme.breakpoints.down("lg")]: {
              "& > svg": {
                fontSize: "1rem",
              },
            },
          })}
          size="small"
          onClick={() => {
            onExport(id);
          }}
        >
          <Download />
        </IconButton>
      ))),
  },
];

export const usePaymentHistoryTable: () => UsePaymentHistoryTable = () => {
  const dispatch: <A>(A) => A = useDispatch();
  const baseProps: UseTableHook<*> = useTable(PAYMENT_HISTORY_TABLE);
  const fields: string[] = useSelector((state: *): string[] => getTableFields(state, PAYMENT_HISTORY_TABLE));
  const isLoadingExport: boolean = useSelector((state: StoreWithLoading): boolean => getLoadingState(state, `${PAYMENT_HISTORY_TABLE}-export`));

  const {
    changeLoading: changeExportLoading,
    onFail: onExportFail,
    onFinally: onExportFinally,
  }: UseHandleRequestHook = useHandleRequest(`${PAYMENT_HISTORY_TABLE}-export`);

  const onExportSingle: (id: number) => mixed = async (id) => {
    changeExportLoading(true);

    exportToPdf(id)
      .then(({ data, headers }: Response<mixed>) => {
        const name: string = getFileNameFromHeaders(headers);
        downloadPdf(data, name);
      })
      .catch(async (error: *) => {
        if (error.response.data instanceof Blob) {
          const { message }: { message: string } = await readObjFromFile(error.response.data);
          dispatch(addNotification({ message, severity: "error" }));
        }
        else {
          onExportFail(error);
        }
      })
      .finally(onExportFinally);
  };

  const columns: Column<PaymentHistoryColumnData>[] = getColumns(onExportSingle, isLoadingExport)
    .filter(({ field }:Column<PaymentHistoryColumnData>): boolean => [...fields, "invoice"].includes(field));
  const isLoadingTable: boolean = baseProps.isLoading || isLoadingExport;
  return {
    ...baseProps,
    columns,
    isLoading: isLoadingTable,
    data: baseProps.isLoading ? [] : baseProps.data,
  };
};

function getPaymentHistoryRequest(store) {
  const state = store.getState();

  const filters: Filters = getTableFilters(state, PAYMENT_HISTORY_TABLE);
  const tableSorting: Sorting = getTableSorting(state, PAYMENT_HISTORY_TABLE);
  const page: number = getTablePage(state, PAYMENT_HISTORY_TABLE);
  const limit: number = getTablePageSize(state, PAYMENT_HISTORY_TABLE);
  const fields: string[] = getTableFields(state, PAYMENT_HISTORY_TABLE);
  const [current = "created", direction = "desc"]: [string, mixed] = Object.entries(tableSorting)[0] || [];
  const { dateFrom: from, dateTo: to }: Filters = filters;

  const requestBody: RequestBody = {
    filters: { created: [{ from, to }] },
    sorting: { current, direction },
    fields,
    page,
    limit,
  };

  return requestBody;
}

export const usePaymentHistoryActions: () => UsePaymentHistoryActions = () => {
  const store = useStore();
  const dispatch: <A>(A) => A = useDispatch();

  const {
    isLoading,
    changeLoading,
    onFail,
    onFinally,
  }: UseHandleRequestHook = useHandleRequest(PAYMENT_HISTORY_TABLE);

  const {
    isLoading: isExportLoading,
    changeLoading: changeExportLoading,
    onFail: onExportFail,
    onFinally: onExportFinally,
  }: UseHandleRequestHook = useHandleRequest(`${PAYMENT_HISTORY_TABLE}-export`);

  const filters: Filters = useSelector((state: *): Filters => getTableFilters(state, PAYMENT_HISTORY_TABLE));
  const tableSorting: Sorting = useSelector((state: *): Sorting => getTableSorting(state, PAYMENT_HISTORY_TABLE));
  const page: number = useSelector((state: *): number => getTablePage(state, PAYMENT_HISTORY_TABLE));
  const limit: number = useSelector((state: *): number => getTablePageSize(state, PAYMENT_HISTORY_TABLE));
  const tableData: PaymentHistoryData[] = useSelector(
    (state: *): PaymentHistoryData[] => getTableData(state, PAYMENT_HISTORY_TABLE)
  );

  useEffect(() => {
    onApply();
  }, [tableSorting, page, limit]);

  const onApply: () => mixed = () => {
    const requestBody: RequestBody = getPaymentHistoryRequest(store);
    if (!isEqualPrevious(requestBody.filters) && requestBody.page !== 1) {
      dispatch(changeTablePage(PAYMENT_HISTORY_TABLE, 1));
      return;
    }
    changeLoading(true);

    fetchPaymentHistoryList(requestBody)
      .then(({ data, count }: Response<PaymentHistoryData[]>) => {
        dispatch(setTableData(PAYMENT_HISTORY_TABLE, data));
        dispatch(changeTableItemsTotalAmount(PAYMENT_HISTORY_TABLE, count));
      })
      .catch(onFail)
      .finally(onFinally);
  };

  const onExport: () => mixed = async () => {
    changeExportLoading(true);
    const requestBody: RequestBody = getPaymentHistoryRequest(store);

    exportToZip(requestBody)
      .then(({ data, headers }: Response<mixed>) => {
        const name: string = getFileNameFromHeaders(headers);
        downloadZip(data, name);
      })
      .catch(async (error: *) => {
        if (error.response.data instanceof Blob) {
          const { message }: { message: string } = await readObjFromFile(error.response.data);
          dispatch(addNotification({ message, severity: "error" }));
        }
        else {
          onExportFail(error);
        }
      })
      .finally(onExportFinally);
  };

  const onChangeDate: ({ startDate: string, endDate: string }) => mixed = ({
    startDate,
    endDate,
  }) => {
    if (filters.dateFrom !== startDate || filters.dateTo !== endDate) {
      dispatch(changeTableFilters(
        PAYMENT_HISTORY_TABLE,
        { ...filters, dateFrom: startDate, dateTo: endDate }
      ));
      onApply();
    }
  };

  return {
    filters,
    isLoading,
    isExportLoading,
    isNoData: tableData.length === 0,
    onChangeDate,
    onApply,
    onExport,
  };
};
